<template>
  <div class="page">
    <h1><w-icon>mdi mdi-comment-processing-outline</w-icon>コラム</h1>
    <div class="content">
      <w-card title="新発想「わけりんぐ」 - 研究のきっかけ" title-class="grey-light5--bg">
        <p>
          娘の毎日の宿題である九九の答えを声に出しながら捲る学習カード<br><br>
          素早く計算して間違えも少なく上手に取り組んでいるように見えたのですが、娘が言うには、「隣の席の子はいつも自分より早く終わってすごい」とのこと でも、どうやらその子が声に出している答えは間違っていることも多いとのこと<br><br>
          「なるほど、じゃぁ、皆で自分の間違えた問題だけをやり直して、すべてが正解し終えた時間を競争するようにすれば、公平に競い合って楽しみながら九九が学習できるねっ」と、自信満々に言った後の、<br><br>
          「 は？そんなこと仕組み的にできないし... 」と言いたげな娘の冷たい眼差しを浴びたその日から長きに渡る研究が始まりました☆<br><br>
          ミヒラク
          <w-icon>mdi mdi-emoticon-wink-outline</w-icon>
        </p>
      </w-card>
    </div>
  </div>
</template>

<script>
console.log('i am column')
export default {
}
</script>

<style lang="scss" scoped>
</style>
